import { action, computed, makeObservable, observable, runInAction, toJS } from "mobx";
import { RootStore } from "./RootStore";
import { BaseStore } from "./BaseStore";
import { orderBy } from "lodash";
import { Project } from "../models/Project";
import { HTTPError } from "./errors";

export interface FileMeta {
  size: number;
  type: string;
  name: string;
  width: number;
  height: number;
}

interface AssetUploadParams {
  project: Project;
  asset_meta: AssetMeta;
}

interface AssetMeta {
  url: string;
  s3_upload_params: S3UploadParams;
}

interface Size {
  width: number;
  height: number;
}

interface S3UploadParams {
  success_action_status: string;
  x_amz_credential: string;
  x_amz_algorithm: string;
  x_amz_date: string;
  x_amz_signature: string;
}

export class ProjectStore extends BaseStore<Project> {
  currentProject: Project | null = null;
  uploadProjectError: string | null = null;
  isUploadingAssets = false;

  constructor(rootStore: RootStore) {
    super(rootStore, Project);
    makeObservable(this, {
      currentProject: observable,
      uploadProjectError: observable,
      isUploadingAssets: observable,
      projects: computed,
      archive: action,
      uploadFile: action,
      setCurrentProject: action,
      setIsUploadingAsset: action,
    });
    this.fetchPage();
  }

  get projects() {
    return [...this.data.values()];
  }

  setIsUploadingAsset(value: boolean) {
    this.isUploadingAssets = value;
  }

  fetchProjects = (
    status: string,
    sortKeys: string[],
    sortKeyDirections: Array<"asc" | "desc">
  ) => {
    return orderBy(
      this.projects.filter((p) => p.status === status),
      sortKeys,
      sortKeyDirections
    );
  };

  async setCurrentProject(project_id: string) {
    await this.fetch(project_id, { force: true }).catch((e) => {
      if (e instanceof HTTPError) {
        this.rootStore.uiStore.setHTTPError(e);
      } else {
        // handle other errors here
        throw e;
      }
    });

    runInAction(() => {
      this.currentProject = this.data.get(project_id)!;
    });
  }

  async uploadAndCreateProject(file: File): Promise<Project> {
    this.isUploadingAssets = true;

    try {
      const dims = await this.getDimensions(file);
      const uploadParams = await this.uploadFile({
        size: file.size,
        type: file.type,
        name: file.name,
        width: dims.width,
        height: dims.height,
      });

      if (!uploadParams) {
        throw new Error("Something went wrong");
      }

      const res = await this.uploadToS3(file, uploadParams.asset_meta).catch((e) => {
        throw e;
      });

      console.log(res);

      if (res && res.ok) {
        return uploadParams.project;
      } else {
        throw new Error("Something went wrong");
      }
    } finally {
      this.isUploadingAssets = false;
    }
  }

  async uploadToS3(file: File, uploadParams: AssetMeta) {
    const formData = new FormData();
    formData.append("Content-Type", "image/png");
    Object.entries(uploadParams.s3_upload_params).forEach(([k, v]) => {
      formData.append(k, v);
    });
    formData.append("file", file);

    return await fetch(uploadParams.url, {
      method: "POST",
      body: formData,
    });
  }

  async uploadFile(fileMeta: FileMeta): Promise<AssetUploadParams | null> {
    this.uploadProjectError = null;
    const res = await this.clientPost(
      `/api/${this.modelEndpoint}/create`,
      { body: JSON.stringify({ fileMeta }) },
      {}
    );
    const data = await res.json();
    if (data.status === 406) {
      runInAction(() => {
        this.uploadProjectError = data.error;
      });
      throw data.error;
    } else {
      this.add(data);
      return data;
    }
  }

  async archive(item: Project, options: Record<string, any> = {}) {
    this.isSaving = true;

    try {
      const res = await this.clientPost(
        `/api/${this.modelEndpoint}/archive`,
        { body: JSON.stringify({ id: item.id }) },
        {}
      );
      const response = await res.json();

      runInAction(() => {
        return this.add({ ...response, id: String(response.id) });
      });
    } finally {
      this.isSaving = false;
    }
  }

  async unarchive(item: Project, options: Record<string, any> = {}) {
    this.isSaving = true;

    try {
      const res = await this.clientPost(
        `/api/${this.modelEndpoint}/unarchive`,
        { body: JSON.stringify({ id: item.id }) },
        {}
      );
      const response = await res.json();

      runInAction(() => {
        return this.add({ ...response, id: String(response.id) });
      });
    } finally {
      this.isSaving = false;
    }
  }

  async updateCurrentProject(params: Record<string, any>) {
    if (!this.currentProject) {
      return;
    }

    return this.update({ id: this.currentProject.id, ...params });
  }

  getDimensions(file: File): Promise<Size> {
    return new Promise<Size>((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        var image = new Image();
        image.src = e.target?.result;
        image.onload = () => {
          resolve({ width: image.naturalWidth, height: image.naturalHeight });
          return true;
        };
        image.onerror = () => {
          reject("This file is not supported");
          return true;
        };
      };
    });
  }
}
