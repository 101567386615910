import { IObservableArray, action, makeObservable, observable, reaction, runInAction } from "mobx";
import { RootStore } from "./RootStore";
import { HTTPError } from "./errors";

interface SortProperty {
  key: string;
  direction: "asc" | "desc";
}
type TabsOption = "active" | "archived" | "settings";

export class UiStore {
  store: RootStore;
  sortProperties: IObservableArray<SortProperty> = observable.array([
    { key: "createdAt", direction: "desc" },
  ]);
  tabsValue: TabsOption = "active";
  commentsSidebarOpen = true;
  httpError?: HTTPError = undefined;
  isProjectPermissionDialogOpen = false;

  constructor(store: RootStore) {
    this.store = store;
    makeObservable(this, {
      sortProperties: observable,
      tabsValue: observable,
      commentsSidebarOpen: observable,
      httpError: observable,
      isProjectPermissionDialogOpen: observable,
      setSortProperties: action,
      setHTTPError: action,
      setTabsValue: action,
      toggleCommentsSidebar: action,
    });
  }

  setSortProperties(properties: Array<SortProperty>) {
    this.sortProperties.replace(properties);
  }

  setHTTPError(err: HTTPError) {
    this.httpError = err;
  }

  setIsProjectPermissionDialogOpen(value: boolean) {
    this.isProjectPermissionDialogOpen = value;
  }

  setTabsValue = (value: string) => {
    this.tabsValue = value as TabsOption;
  };

  toggleCommentsSidebar = () => {
    this.commentsSidebarOpen = !this.commentsSidebarOpen;
  };
}
