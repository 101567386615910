import { observer } from "mobx-react";
import React from "react";
import { Avatar, AvatarFallback } from "~/@/components/ui/avatar";
import { User } from "~/models/User";

interface UserAvatarProps {
  user?: User;
  size?: string;
}

export const UserAvatar = observer((props: UserAvatarProps) => {
  return (
    <Avatar
      className="drop-shadow-sm"
      style={{ width: props.size ?? "2rem", height: props.size ?? "2rem" }}
    >
      <AvatarFallback
        style={{ backgroundColor: props.user?.avatarColor }}
        className={`w-full border-2 border-white text-white font-medium text-sm`}
      >
        {props.user?.initials}
      </AvatarFallback>
    </Avatar>
  );
});
