import React from "react";

export const CommentIcon = () => {
  return (
    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.1885 18.1162C14.0127 18.1162 13.8516 18.0693 13.7051 17.9756C13.5586 17.8877 13.3828 17.7471 13.1777 17.5537L9.99609 14.6533H4.14258C3.08789 14.6533 2.25586 14.334 1.64648 13.6953C1.04297 13.0508 0.741211 12.2041 0.741211 11.1553V3.53516C0.741211 2.48047 1.04297 1.65723 1.64648 1.06543C2.25586 0.467773 3.08789 0.168945 4.14258 0.168945H15.9551C16.9688 0.168945 17.7891 0.464844 18.416 1.05664C19.043 1.64844 19.3564 2.47461 19.3564 3.53516V11.1553C19.3564 11.8584 19.2129 12.4736 18.9258 13.001C18.6445 13.5225 18.2461 13.9297 17.7305 14.2227C17.2207 14.5098 16.6289 14.6533 15.9551 14.6533H14.9707V17.2109C14.9707 17.4863 14.9004 17.7061 14.7598 17.8701C14.625 18.0342 14.4346 18.1162 14.1885 18.1162Z"
        fill="black"
      />
    </svg>
  );
};
