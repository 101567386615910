import { observable, action, makeObservable, computed, runInAction } from "mobx";
import { RootStore } from "./RootStore";
import { lowerFirst, pick } from "lodash";
import { BaseModel } from "../models/BaseModel";
import { AuthError, HTTPErrorFactory, NotFoundError } from "./errors";

type Class<T> = {
  new (...args: any[]): T;
};

export type PartialWithId<T> = Partial<T> & { id: string };

export abstract class BaseStore<T extends BaseModel> {
  rootStore: RootStore;
  data: Map<string, T> = new Map();
  model: Class<T>;
  isFetching = false;
  isSaving = false;
  isLoaded = false;

  constructor(rootStore: RootStore, model: Class<T>) {
    makeObservable(this, {
      rootStore: observable,
      data: observable,
      isFetching: observable,
      isSaving: observable,
      isLoaded: observable,
      add: action,
      remove: action,
      update: action,
      delete: action,
      fetch: action,
      fetchPage: action,
    });
    this.rootStore = rootStore;
    this.model = model;
  }

  // Move this to a client object
  get csrfToken() {
    const token = document.getElementsByName("csrf-token")[0] as HTMLMetaElement;
    if (token) {
      return { "X-CSRF-Token": token.content };
    } else {
      return { "X-CSRF-Token": "" };
    }
  }

  get modelEndpoint() {
    return `${lowerFirst(this.model.name)}s`;
  }

  add = (fields: PartialWithId<T> | T): T => {
    const ModelClass = this.model;

    if (!(fields instanceof ModelClass)) {
      const existingModel = this.data.get(fields.id);

      if (existingModel) {
        // This doesn't recurse properly
        existingModel.updateFromJson(fields);
        existingModel.tailCalls();
        return existingModel;
      }

      const newModel = new ModelClass(this.rootStore, fields);
      this.data.set(newModel.id, newModel);
      return newModel;
    }

    this.data.set(fields.id, fields);
    return fields;
  };

  remove(id: string): void {
    this.data.delete(id);
  }

  get(id: string): T | undefined {
    return this.data.get(id);
  }

  clientPost = (
    path: string,
    params: Record<string, string>,
    options?: Record<string, string | boolean | number | FormData | object | undefined>
  ) => {
    return fetch(path, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...this.csrfToken,
      },
      ...params,
      ...options,
    });
  };

  clientGet = (
    path: string,
    params: Record<string, string>,
    options?: Record<string, string | boolean | number | undefined>
  ) => {
    return fetch(path, {
      headers: { "Content-Type": "application/json" },
      ...params,
      ...options,
    });
  };

  async create(
    params: Partial<T>,
    options?: Record<string, string | boolean | number | undefined>
  ): Promise<T> {
    this.isSaving = true;

    try {
      const res = await this.clientPost(
        `/api/${this.modelEndpoint}/create`,
        { body: JSON.stringify(params) },
        options
      );
      const response = await res.json();
      return this.add(response);
    } finally {
      this.isSaving = false;
    }
  }

  async update(
    params: Partial<T>,
    options?: Record<string, string | boolean | number | undefined>
  ): Promise<T> {
    this.isSaving = true;

    try {
      const res = await this.clientPost(
        `/api/${this.modelEndpoint}/update`,
        { body: JSON.stringify(params) },
        options
      );
      const response = await res.json();
      return this.add(response);
    } finally {
      this.isSaving = false;
    }
  }

  async delete(item: T, options: Record<string, any> = {}) {
    this.isSaving = true;

    try {
      const res = await this.clientPost(
        `/api/${this.modelEndpoint}/delete`,
        { body: JSON.stringify({ id: item.id }) },
        {}
      );
      const response = await res.json();
      return this.remove(item.id);
    } finally {
      this.isSaving = false;
    }
  }

  async fetch(id: string, options: Record<string, any> = {}): Promise<T> {
    const item = this.data.get(id);
    if (item && !options.force) {
      return item;
    }
    this.isFetching = true;

    try {
      const raw = await this.clientGet(`/api/${this.modelEndpoint}/${id}`, {}, options);
      if (!raw.ok) {
        throw HTTPErrorFactory.error(raw.status);
      }

      const response = await raw.json();
      return this.add(response);
    } catch (err) {
      throw err;
    } finally {
      runInAction(() => {
        this.isFetching = false;
      });
    }
  }

  async fetchPage(options: Record<string, any> = {}): Promise<Array<T>> {
    this.isFetching = true;

    try {
      const raw = await this.clientGet(`/api/${this.modelEndpoint}/list`, {}, options);
      const response = await raw.json();
      let allModels: Array<T> = [];
      runInAction(() => {
        allModels = response.map(this.add);
        this.isLoaded = true;
      });
      return allModels;
    } catch (err) {
      throw err;
    } finally {
      runInAction(() => {
        this.isFetching = false;
      });
    }
  }
}
