import React from "react"

export const PortraitIcon = () => {
    return (
        <svg width="34" height="36" viewBox="0 0 34 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.75 21.5625C12.2812 21.8854 12.8698 22.0469 13.5156 22.0469C14.1615 22.0469 14.75 21.8854 15.2812 21.5625C15.8229 21.2396 16.25 20.8125 16.5625 20.2812C16.8854 19.7396 17.0469 19.151 17.0469 18.5156C17.0469 17.8698 16.8854 17.2812 16.5625 16.75C16.25 16.2188 15.8229 15.7969 15.2812 15.4844C14.75 15.1615 14.1615 15 13.5156 15C12.8698 15 12.2812 15.1615 11.75 15.4844C11.2188 15.7969 10.7917 16.2188 10.4688 16.75C10.1562 17.2812 10 17.8698 10 18.5156C10 19.151 10.1562 19.7396 10.4688 20.2812C10.7917 20.8125 11.2188 21.2396 11.75 21.5625Z" fill="#FF8C21" />
            <path d="M5.125 35.4062C3.4375 35.4062 2.16146 34.9792 1.29688 34.125C0.432292 33.2708 0 32.0052 0 30.3281V14.2656C0 12.5885 0.432292 11.3229 1.29688 10.4688C2.16146 9.61458 3.4375 9.1875 5.125 9.1875H27.8906C29.5781 9.1875 30.8542 9.61979 31.7188 10.4844C32.5938 11.3385 33.0312 12.599 33.0312 14.2656V30.3281C33.0312 31.9948 32.5938 33.2552 31.7188 34.1094C30.8542 34.974 29.5781 35.4062 27.8906 35.4062H5.125ZM5.3125 32.3438H27.7031C28.4219 32.3438 28.974 32.151 29.3594 31.7656C29.7552 31.3906 29.9531 30.8229 29.9531 30.0625V14.5312C29.9531 13.7708 29.7552 13.2031 29.3594 12.8281C28.974 12.4427 28.4219 12.25 27.7031 12.25H5.3125C4.58333 12.25 4.02604 12.4427 3.64062 12.8281C3.26562 13.2031 3.07812 13.7708 3.07812 14.5312V30.0625C3.07812 30.8229 3.26562 31.3906 3.64062 31.7656C4.02604 32.151 4.58333 32.3438 5.3125 32.3438ZM6.625 2.125C6.66667 1.42708 6.89062 0.901042 7.29688 0.546875C7.71354 0.182292 8.29688 0 9.04688 0H23.9844C24.7344 0 25.3125 0.182292 25.7188 0.546875C26.125 0.901042 26.3594 1.42708 26.4219 2.125H6.625Z" fill="#4A4A4A" />
            <path d="M5.39062 34.5156C3.94271 34.5156 2.84896 34.1458 2.10938 33.4062C1.36979 32.6771 1 31.5625 1 30.0625L6.4375 25.3125C6.75 25.0417 7.0625 24.8385 7.375 24.7031C7.6875 24.5573 8.00521 24.4844 8.32812 24.4844C8.68229 24.4844 9.02083 24.5573 9.34375 24.7031C9.67708 24.8385 9.99479 25.0469 10.2969 25.3281L12.9375 27.6875L19.5312 21.875C19.8646 21.5729 20.2083 21.3542 20.5625 21.2188C20.9271 21.0729 21.3073 21 21.7031 21C22.1094 21 22.5 21.0729 22.875 21.2188C23.25 21.3646 23.599 21.5833 23.9219 21.875L31.6719 29.1875V30.125C31.6719 31.5938 31.3021 32.6927 30.5625 33.4219C29.8229 34.151 28.7292 34.5156 27.2812 34.5156H5.39062Z" fill="#0F6800" />
            <path d="M5.125 35.2188C3.4375 35.2188 2.16146 34.7917 1.29688 33.9375C0.432292 33.0833 0 31.8177 0 30.1406V14.0781C0 12.401 0.432292 11.1354 1.29688 10.2812C2.16146 9.42708 3.4375 9 5.125 9H27.8906C29.5781 9 30.8542 9.43229 31.7188 10.2969C32.5938 11.151 33.0312 12.4115 33.0312 14.0781V30.1406C33.0312 31.8073 32.5938 33.0677 31.7188 33.9219C30.8542 34.7865 29.5781 35.2188 27.8906 35.2188H5.125ZM5.3125 32.1562H27.7031C28.4219 32.1562 28.974 31.9635 29.3594 31.5781C29.7552 31.2031 29.9531 30.6354 29.9531 29.875V14.3438C29.9531 13.5833 29.7552 13.0156 29.3594 12.6406C28.974 12.2552 28.4219 12.0625 27.7031 12.0625H5.3125C4.58333 12.0625 4.02604 12.2552 3.64062 12.6406C3.26562 13.0156 3.07812 13.5833 3.07812 14.3438V29.875C3.07812 30.6354 3.26562 31.2031 3.64062 31.5781C4.02604 31.9635 4.58333 32.1562 5.3125 32.1562Z" fill="#202020" />
            <path d="M4.76562 4.60938C4.36979 5.01562 4.11458 5.58854 4 6.32812H30.1562C30.0417 5.58854 29.7812 5.01562 29.375 4.60938C28.9792 4.20312 28.3646 4 27.5312 4H6.625C5.79167 4 5.17188 4.20312 4.76562 4.60938Z" fill="#343434" />
        </svg>

    )
}