import React from "react";
import { observer } from "mobx-react";
import { useRootStoreContext } from "./context";
import { TabsContent } from "~/@/components/ui/tabs";
import { Button } from "~/@/components/ui/button";
import { toast } from "~/@/components/ui/use-toast";

export const SettingsView = observer(({ value }: { value: string }) => {
  const { authStore } = useRootStoreContext();

  return (
    <TabsContent value={value}>
      <div className="flex flex-col items-center justify-center">
        <div className="w-full px-5 sm:p-0 sm:w-3/5">
          <div className="border-b pb-2 sm:mt-6">
            <div className="text-3xl font-medium mb-2">Preferences</div>
            <span className="text-slate-600 text-sm">Manage your personal preferences</span>
          </div>
          <div className="flex flex-col border-b py-8 gap-2">
            <span className="font-semibold">Identity</span>
            <div className="p-8 gap-2 bg-[#F6F6F6] rounded-md flex flex-col border">
              <div className="flex flex-col gap-2">
                <span className="font-medium">Full Name</span>
                <input
                  className="rounded-md p-3 border max-w-md"
                  value={authStore.tempName}
                  onChange={(e) => {
                    authStore.setTempName(e.target.value);
                  }}
                />
              </div>
              <div className="flex flex-col gap-2">
                <span className="font-medium">Email</span>
                <input
                  className="rounded-md p-3 border max-w-md disabled:opacity-75"
                  disabled
                  value={authStore.user?.email}
                  onChange={() => {}}
                />
              </div>
              <Button
                className="mt-4 place-self-start"
                onClick={async () => {
                  try {
                    authStore.updateSettings();
                    toast({ title: "✅ Name updated" });
                  } catch (e) {
                    toast({ title: "Something went wrong" });
                  }
                }}
              >
                Update
              </Button>
            </div>
          </div>
          {/* <div className="flex flex-col gap-4 py-4">
            <h3 className="text-red-600 font-medium">Danger Zone</h3>
            <p className="text-slate-600 font-medium text-sm">
              Permanently delete your account and all data from studiolanes and its data providers.
              This is an irreversible action so please proceed with caution.
            </p>
            <Button variant="destructive" className="place-self-start">
              Nuke it
            </Button>
          </div> */}
        </div>
      </div>
    </TabsContent>
  );
});
