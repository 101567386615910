import React from "react";
import { ChevronDownIcon, GlobeIcon, LockClosedIcon } from "@radix-ui/react-icons";
import { DialogContent, DialogTitle } from "~/@/components/ui/dialog";
import { Button } from "~/@/components/ui/button";
import { Input } from "~/@/components/ui/input";
import { Badge } from "~/@/components/ui/badge";
import { useRootStoreContext } from "./context";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
} from "~/@/components/ui/dropdown-menu";
import { toast } from "~/@/components/ui/use-toast";
import { Copy } from "lucide-react";

const PERM_ANYONE_WITH_LINK_TITLE = "Anyone with link";
const PERM_ONLY_YOU_TITLE = "Only you";

export const ShareModal = () => {
  const { uiStore, projectStore } = useRootStoreContext();

  const setVisibility = (visibility: number) => {
    projectStore.updateCurrentProject({ visibility: Number(visibility) });
  };

  return (
    <div className="flex flex-col gap-4">
      <DialogTitle>Sharing</DialogTitle>
      <div className="flex flex-col gap-2 items-start mt-4">
        <Badge variant="outline">Coming soon</Badge>
        <div className="p-2 bg-slate-100 rounded-lg flex flex-col gap-2 w-full">
          <div className="flex items-start gap-2 w-full">
            <Input
              disabled
              className="grow"
              type="email"
              id="email"
              placeholder="Add users to the project..."
            />
            <Button disabled>Invite</Button>
          </div>
        </div>
      </div>
      <div className="h-[1px] w-full bg-slate-200 my-2" />
      <div className="flex items-center">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline">
              {projectStore.currentProject?.isPrivate ? (
                <>
                  <LockClosedIcon className="text-slate-500 mr-2" />
                  {PERM_ONLY_YOU_TITLE}
                </>
              ) : (
                <>
                  <GlobeIcon className="text-[#5CCF3F] mr-2" />
                  {PERM_ANYONE_WITH_LINK_TITLE}
                </>
              )}
              <ChevronDownIcon className="ml-2" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuItem onSelect={() => setVisibility(0)}>
              <div className="flex gap-4 items-center px-2">
                <LockClosedIcon className="text-slate-500" />
                <div className="flex flex-col gap-1">
                  <div>{PERM_ONLY_YOU_TITLE}</div>
                  <div>This post is only visible to you</div>
                </div>
              </div>
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem onSelect={() => setVisibility(1)}>
              <div className="flex gap-4 items-center px-2">
                <GlobeIcon className="text-[#5CCF3F]" />
                <div className="flex flex-col gap-1">
                  <div>{PERM_ANYONE_WITH_LINK_TITLE}</div>
                  <div>All users with link can access</div>
                </div>
              </div>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
        <div className="grow" />
        <Button
          variant="ghost"
          onClick={() => {
            navigator.clipboard.writeText(window.location.href);
            toast({
              title: "Copied link to clipboard",
            });
            uiStore.setIsProjectPermissionDialogOpen(false);
          }}
        >
          <Copy size={15} className="mr-2" />
          Copy Link
        </Button>
      </div>
    </div>
  );
};
