import React from "react";
import { RootStore } from "~/stores/RootStore";
import { useContext } from "react";

export const RootStoreContext = React.createContext<undefined | RootStore>(
  undefined
);

export const useRootStoreContext = () => {
  const rootStore = useContext(RootStoreContext);

  if (rootStore === undefined) {
    throw new Error("No store");
  }
  return rootStore;
};
