import { BaseModel } from "./BaseModel";
import { RootStore } from "~/stores/RootStore";
import { computed, makeObservable, observable } from "mobx";
import { User } from "./User";
import formatDistanceToNowStrict from "date-fns/formatDistanceToNowStrict";
import { format } from "date-fns";
import { Coordinate2D } from "~/stores/CanvasStore";

const TWO_DAYS_IN_SECONDS = 172_800;

export class Comment extends BaseModel {
  text: string = "";
  user_id?: string;
  sketch_id?: string;
  asset_id?: string;
  point?: Coordinate2D;
  user: User | null = null;

  constructor(store: RootStore, data: any) {
    super(store, data);
    makeObservable(this, {
      text: observable,
      sketch_id: observable,
      asset_id: observable,
      point: observable,
      user_id: observable,
      user: observable,
      humanizedDateString: computed,
    });

    this.updateFromJson(data);
    this.tailCalls()
  }

  tailCalls(): void {
    if (this.user) {
      this.user = this.store.userStore.add(this.user);
    }
  }

  get humanizedDateString(): string {
    const now = new Date();
    const date = this.createdAt;
    if (!date) throw new Error("Invalid Date");

    const secondsSinceNow = (now.getTime() - date.getTime()) / 1000;

    if (secondsSinceNow < 60) {
      return "Just now";
    } else if (secondsSinceNow < TWO_DAYS_IN_SECONDS) {
      return formatDistanceToNowStrict(date);
    } else {
      if (date.getFullYear() === now.getFullYear()) {
        return format(date, "MMM d");
      } else {
        return format(date, "MMM d, YYY");
      }
    }
  }
}
