import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { DialogContent } from "~/@/components/ui/dialog";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import { Button } from "~/@/components/ui/button";
import { useDropzone } from "react-dropzone";
import { useRootStoreContext } from "./context";
import { PortraitIcon } from "./PortraitIcon";
import { Upload, Info, Loader2 } from "lucide-react";
import { cn } from "~/@/lib/utils";
import { observer } from "mobx-react";
import { useToast } from "~/@/components/ui/use-toast";

const FileDropzone = observer(() => {
  const { projectStore } = useRootStoreContext();
  const navigate = useNavigate();
  const { toast } = useToast();

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];

    try {
      const project = await projectStore.uploadAndCreateProject(file);
      navigate(`projects/${project.id}`);
    } catch (err) {
      var reason: string = "";
      if (err instanceof Error) {
        reason = err.message;
      } else {
        // From promise reject
        reason = err as string;
      }

      toast({
        title: "⛔  Failed to upload",
        description: reason ?? "Hmmm.. something went wrong. Please try again.",
      });
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <section
      className={cn(
        "border-dashed border-[#43CF94] border-2 my-8 rounded-md w-full",
        projectStore.uploadProjectError && "border-[#E3242B]"
      )}
    >
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <div className="flex flex-col text-slate-700 items-center gap-6 p-20">
          <PortraitIcon />
          <Button variant="outline">
            {projectStore.isUploadingAssets ? (
              <>
                <Loader2 className="mr-2 animate-spin" size={20} />
                Uploading
              </>
            ) : (
              <>
                <Upload className="mr-2" size={20} />
                Upload files
              </>
            )}
          </Button>
          <div className={`flex items-center grow ${projectStore.isUploadingAssets ? "opacity-0" : "opacity-100"} transition-opacity`}>
            <p className="mr-2">Drag and drop images</p>
            <Info size={20} />
          </div>
        </div>
      </div>
    </section>
  );
});

export const CreatePostDialogContent = () => {
  const navigate = useNavigate();

  return (
    <DialogContent className="fixed h-screen w-screen min-w-full max-w-3xl items-center flex justify-center bg-slate-100">
      <div className="z-20 bg-white flex rounded-lg justify-center items-center flex-col w-full max-w-4xl drop-shadow-md p-18">
        <div className="max-w-2xl w-full p-12 flex flex-col items-center">
          <div className="text-2xl text-left font-semibold w-full my-4">
            Show off what you have <br />
            been working on
          </div>

          <FileDropzone />
          <DialogPrimitive.Close className="place-self-start">
            <Button variant="outline" onClick={() => navigate("/")}>
              Cancel
            </Button>
          </DialogPrimitive.Close>
        </div>
      </div>
      <div className="backdrop-blur-xl blur-3xl bg-white/30 absolute z-10 -left-[40vw] -bottom-[40vw] w-full flex items-center">
        <div className="bg-green-400/75 h-[80vw] w-[80vw] aspect-square rounded-full" />
      </div>
    </DialogContent>
  );
};
