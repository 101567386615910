import React from "react";

export const CursorIcon = () => {
  return (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.00006 1L5.00006 13.5L8.58006 9.58L12.5001 5.5L1.00006 1Z" fill="black" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.315651 0.270869C0.596976 0.00680622 1.00512 -0.0718506 1.36443 0.0687495L12.8644 4.56875C13.1754 4.69043 13.4051 4.95931 13.4768 5.28547C13.5484 5.61163 13.4525 5.95202 13.2211 6.19282L9.3099 10.2637L5.73844 14.1744C5.49782 14.4378 5.13506 14.5531 4.78649 14.4769C4.43792 14.4007 4.15635 14.1446 4.04761 13.8048L0.0476084 1.30477C-0.0699865 0.937284 0.034325 0.534931 0.315651 0.270869ZM2.59309 2.6972L5.42615 11.5505L7.84163 8.90563C7.84732 8.8994 7.85308 8.89325 7.85893 8.88717L10.7424 5.88604L2.59309 2.6972Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.18635 7.73282C7.57553 7.34096 8.2087 7.33879 8.60055 7.72798L14.7047 13.7905C15.0966 14.1797 15.0988 14.8128 14.7096 15.2047C14.3204 15.5965 13.6872 15.5987 13.2954 15.2095L7.19119 9.14702C6.79933 8.75784 6.79717 8.12468 7.18635 7.73282Z"
        fill="black"
      />
    </svg>
  );
};
