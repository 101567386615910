import { Observer, observer } from "mobx-react";
import React, { useEffect } from "react";
import { useRootStoreContext } from "./context";
import { Link, useNavigate, useParams } from "react-router-dom";
import { PanelRight, Lock, Globe, Send, Download, ChevronDown } from "lucide-react";
import { Button } from "~/@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "~/@/components/ui/dropdown-menu";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "~/@/components/ui/tooltip";
import { Comment } from "~/models/Comment";
import { Logo } from "./Logo";
import { cn } from "~/@/lib/utils";
import { Asset } from "~/models/Asset";
import { runInAction, toJS } from "mobx";
// @ts-ignore
import Measure from "react-measure";
import { CursorIcon } from "./CursorIcon";
import { CommentIcon } from "./CommentIcon";
import { HotKeyToolTip } from "./KeyTooltip";
import { Toggle } from "~/@/components/ui/toggle";
import { useToast } from "~/@/components/ui/use-toast";
import { Dialog, DialogContent, DialogTrigger } from "~/@/components/ui/dialog";
import { Foobar } from "./Foobar";
import { Input } from "~/@/components/ui/input";
import scrollIntoView from "scroll-into-view-if-needed";
import { UserAvatar } from "./UserAvatar";
import { ShareModal } from "./ShareModal";

const TITLE_INPUT_PLACEHOLDER = "Untitled.";

const StudioLanesDropdown = () => {
  const navigate = useNavigate();

  return (
    <Button variant="ghost" onClick={() => navigate("/")}>
      <Logo fill="black" />
    </Button>
  );
};

const MenuBar = observer(() => {
  const { authStore, projectStore, uiStore } = useRootStoreContext();
  // used to retain original title incase the user tries to save an empty title
  const [localTitle, setLocalTitle] = React.useState("");
  const [width, setWidth] = React.useState(10);
  const titleInputRef = React.createRef<HTMLInputElement>();

  useEffect(() => {
    setTitleInput(projectStore.currentProject?.title ?? "");
  }, []);

  const setTitleInput = (text: string) => {
    const length = text.length === 0 ? TITLE_INPUT_PLACEHOLDER.length : text.length;
    setWidth(length);
    setLocalTitle(text);
  };

  return (
    <div className="min-h-[64px] border-b-[1px] border-slate-200 flex p-2 justify-center items-center bg-white z-10">
      <div className="flex flex-1">
        <StudioLanesDropdown />
      </div>
      <div className="flex sm:flex-1 gap-2 items-center justify-start sm:justify-center w-full">
        <div className="text-slate-400">
          {projectStore.currentProject?.isPrivate ? (
            <Lock size={15} strokeWidth={2.2} />
          ) : (
            <Globe size={15} strokeWidth={2} color={"#5CCF3F"} />
          )}
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            titleInputRef.current?.blur();

            projectStore.currentProject?.setTitle(localTitle);
            // Read from project store as it is the ground truth
            const finalTitle = projectStore.currentProject?.title ?? "";
            setTitleInput(finalTitle);
            projectStore.updateCurrentProject({ title: finalTitle });
          }}
        >
          <input
            ref={titleInputRef}
            placeholder={TITLE_INPUT_PLACEHOLDER}
            className="text-left focus:outline-none focus:ring-0 focus:ring-offset-0"
            style={{ width: width + 0.5 + "ch" }}
            value={localTitle}
            onChange={(e) => {
              setTitleInput(e.target.value);
            }}
          />
        </form>
        <div className="grow sm:hidden" />
        <DropdownMenu>
          <DropdownMenuTrigger>
            <div className="flex items-center font-mono text-sm rounded-full bg-slate-100 px-3 py-[6px] gap-2">
              <div className="w-2 h-2 bg-green-400 rounded-full"></div>
              <div className="">v1</div>
              <ChevronDown size={16} color={"#727272"} />
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuItem>💫 Asset versioning coming soon</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <div className="flex flex-1 justify-end gap-2 items-center">
        <Button variant="ghost" className="sm:mr-6">
          <Link
            to={projectStore?.currentProject?.latestSketch?.firstAsset?.url || "_blank"}
            download
          >
            <Download size={20} />
          </Link>
        </Button>
        <Dialog
          open={uiStore.isProjectPermissionDialogOpen}
          onOpenChange={(value) => uiStore.setIsProjectPermissionDialogOpen(value)}
        >
          <DialogTrigger asChild>
            <Button
              disabled={projectStore.isFetching}
              className="gap-2 bg-[#5CCF3F] text-white shadow-lg shadow-[#5CCF3F]/40 hover:bg-[#5CCF3F]/70"
              onClick={() => {
                uiStore.setIsProjectPermissionDialogOpen(true);
              }}
            >
              <Send size={18} />
              <span className="hidden sm:inline">Share</span>
            </Button>
          </DialogTrigger>
          <DialogContent>
            <ShareModal />
          </DialogContent>
        </Dialog>
        <Toggle
          className="hidden sm:flex"
          variant="outline"
          aria-label="Toggle sidebar"
          defaultPressed={true}
          onPressedChange={() => {
            uiStore.toggleCommentsSidebar();
          }}
        >
          <PanelRight />
        </Toggle>
      </div>
    </div>
  );
});

interface CarouselItemProps {
  index: number;
  selectedIndex: number;
  setSelectedIndex: (a: number) => void;
  item: Asset;
}

const CarouselItem = observer((props: CarouselItemProps) => {
  return (
    <div
      key={props.item.id}
      className={cn(
        "border-2 border-slate-950 rounded-md cursor-pointer",
        props.selectedIndex !== props.index && "border-slate-0"
      )}
      onClick={() => props.setSelectedIndex(props.index)}
    >
      <img className="object-contain rounded-md border-2 border-white" src={props.item.url}></img>
    </div>
  );
});

const CarouselItems = observer(({ items }: { items: Asset[] }) => {
  // Should we just move this to the UI store?
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  return items.map((item: Asset, index: number) => {
    return (
      <CarouselItem
        key={item.id}
        item={item}
        index={index}
        setSelectedIndex={setSelectedIndex}
        selectedIndex={selectedIndex}
      />
    );
  });
});

const Carousel = observer(() => {
  const { projectStore } = useRootStoreContext();
  return (
    <div className="flex flex-col w-full gap-2 items-center">
      <CarouselItems items={projectStore.currentProject?.latestSketch?.assets || []} />
      {/* <button
        style={{ aspectRatio: "1" }}
        className="border-[1px] bg-slate-100 rounded-md flex justify-center items-center w-full h-auto"
      >
        <Plus size={32} className="text-slate-400" />
      </button> */}
    </div>
  );
});

const AssetSidebar = observer(({ className }) => {
  const { authStore } = useRootStoreContext();
  return (
    <div
      className={`${cn(
        "min-w-[calc(65px+16px)] max-w-[calc(65px+16px)] h-full border-r-[1px] border-slate-300 flex flex-col items-center overflow-auto p-2 justify-between bg-white z-10",
        className
      )}`}
    >
      <Carousel />
      <UserAvatar user={authStore.user ?? undefined} size={"42px"} />
    </div>
  );
});

const AssetViewerControls = observer(() => {
  const { canvasStore } = useRootStoreContext();

  return (
    <div className="absolute bottom-4 flex">
      <div className="flex bg-white rounded-md justify-center items-center drop-shadow-md p-2 gap-2">
        <Toggle
          pressed={canvasStore.cursorMode === "pointer"}
          onPressedChange={(pressed) => {
            pressed && canvasStore.setCursorMode("pointer");
          }}
        >
          <CursorIcon />
        </Toggle>
        <Toggle
          pressed={canvasStore.cursorMode === "comment"}
          onPressedChange={(pressed) => {
            pressed && canvasStore.setCursorMode("comment");
          }}
        >
          <CommentIcon />
        </Toggle>
        {/* <Select>
          <SelectTrigger className="w-[100px] bg-[#f8f8f8]">
            <SelectValue placeholder="Theme" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="light">Light</SelectItem>
            <SelectItem value="dark">Dark</SelectItem>
            <SelectItem value="system">System</SelectItem>
          </SelectContent>
        </Select> */}
      </div>
    </div>
  );
});

const AssetComments = observer(() => {
  const { canvasStore } = useRootStoreContext();

  if (!canvasStore.stage) {
    return null;
  }

  return canvasStore.sketch?.assetComments?.map((assetComment) => {
    return <PointComment key={assetComment.id} assetComment={assetComment} />;
  });
});

const PointComment = observer(({ assetComment }: { assetComment: Comment }) => {
  const { canvasStore } = useRootStoreContext();

  return (
    <div
      className="absolute rounded-lg text-white select-none cursor-pointer whitespace-nowrap"
      style={{
        left: `${
          canvasStore.scale * (assetComment.point!.x + canvasStore.imageOffset.x) +
          canvasStore.origin.x -
          16
        }px`,
        top: `${
          canvasStore.scale * (assetComment.point!.y + canvasStore.imageOffset.y) +
          canvasStore.origin.y -
          16
        }px`,
      }}
      onClick={() => {
        canvasStore.setActiveCommentID(assetComment.id);
      }}
    >
      <div className="flex items-start gap-4">
        <UserAvatar user={assetComment.user ?? undefined} />

        <div
          className={`flex gap-2 flex-col items-start text-sm ${
            assetComment.id === canvasStore.activeCommentID
              ? "opacity-100 z-10 bg-white rounded-md p-4 drop-shadow-md"
              : "h-0 w-0 overflow-hidden"
          } transition-all ease-in-out duration-150`}
        >
          <div className="flex gap-2">
            <span className="text-slate-800 font-semibold">{assetComment.user?.displayName}</span>
            <span className="text-slate-600">{assetComment.humanizedDateString}</span>
          </div>
          <div className="text-black">{assetComment.text}</div>
          {/* <div className="flex w-full max-w-sm items-center space-x-2 pt-2">
            <Input className="text-black" placeholder="Add a comment" />
            <Button type="submit">Comment</Button>
          </div> */}
        </div>
      </div>
    </div>
  );
});

const NewCommentBubble = observer(() => {
  const { commentStore, canvasStore, authStore } = useRootStoreContext();
  const [text, setText] = React.useState("");

  return (
    <div
      className="absolute rounded-lg text-white select-none cursor-pointer"
      style={{
        left: `${
          canvasStore.scale * (canvasStore.canvasClickCoord!.x + canvasStore.imageOffset.x) +
          canvasStore.origin.x -
          16
        }px`,
        top: `${
          canvasStore.scale * (canvasStore.canvasClickCoord!.y + canvasStore.imageOffset.y) +
          canvasStore.origin.y -
          16
        }px`,
      }}
    >
      <div className="flex items-start gap-2">
        <UserAvatar user={authStore.user ?? undefined} />
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            const newComment = await commentStore.postComment(text, canvasStore.canvasClickCoord);
            if (newComment) {
              runInAction(() => {
                canvasStore.canvasClickCoord = undefined;
                canvasStore.activeCommentID = newComment.id;
              });
            }
          }}
        >
          <div className="flex bg-white rounded-lg text-black p-4 drop-shadow-md ml-2 shrink-0">
            <Input
              className="w-[200px]"
              onChange={(e) => setText(e.target.value)}
              placeholder="Add a comment"
            />
            <Button type="submit" className="ml-2">
              Comment
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
});

const AssetViewer = observer(() => {
  const { projectStore, canvasStore } = useRootStoreContext();
  const sketch = projectStore.currentProject?.latestSketch;

  React.useEffect(() => {
    if (sketch) {
      runInAction(() => {
        canvasStore.sketch = sketch;
      });
    }
    return () => canvasStore.dispose();
  }, [sketch]);

  if (!sketch) {
    return null;
  }

  return (
    <Measure
      bounds
      onResize={(contentRect: any) => {
        canvasStore.setDimensions(contentRect.bounds);
      }}
    >
      {({ measureRef }: { measureRef: React.Ref<any> }) => (
        <Observer>
          {() => (
            <div
              ref={measureRef}
              className="relative flex justify-center items-center w-full h-full bg-[#f6f6f6]"
            >
              <div
                id="container"
                className={`${
                  canvasStore.cursorMode === "pointer" ? "cursor-grab" : "cursor-auto"
                }`}
              />
              {canvasStore.initialized && canvasStore.imageLoaded && <AssetComments />}
              {canvasStore.imageLoaded && canvasStore.canvasClickCoord && <NewCommentBubble />}
              <AssetViewerControls />
            </div>
          )}
        </Observer>
      )}
    </Measure>
  );
});

const AssetContent = observer(() => {
  const { uiStore } = useRootStoreContext();
  return (
    <div
      className={cn(
        "h-full grow flex",
        "transition-width duration-500",
        uiStore.commentsSidebarOpen ? "w-3/4 border-r-[1px] border-slate-300" : "w-full"
      )}
    >
      <AssetSidebar className="hidden sm:flex" />
      <AssetViewer />
    </div>
  );
});

const CommentBlock = observer(({ comment }: { comment: Comment }) => {
  return (
    <div className="flex flex-col p-4 gap-4">
      <div className="flex gap-2 items-center">
        <UserAvatar user={comment.user ?? undefined} />
        <div className="flex flex-col items-start gap-1">
          <span className="text-sm whitespace-nowrap">
            {comment?.user?.name || comment?.user?.email}
          </span>
          <span className="text-xs font-medium text-slate-400 whitespace-nowrap">
            {comment.humanizedDateString}
          </span>
        </div>
      </div>

      <div className="text-sm">{comment?.text}</div>
    </div>
  );
});

const Comments = observer(() => {
  const { projectStore } = useRootStoreContext();
  const ref = React.useRef(null);
  const comments = projectStore.currentProject?.latestSketch?.dateAscComments;
  React.useEffect(() => {
    if (ref.current) {
      scrollIntoView(ref.current, {
        scrollMode: "if-needed",
        block: "nearest",
        inline: "nearest",
      });
    }
  }, [comments]);

  return (
    <div className="overflow-scroll h-full">
      {comments?.map((comment) => {
        return <CommentBlock key={comment.id} comment={comment} />;
      })}
      <div ref={ref} />
    </div>
  );
});

const AddComment = observer(() => {
  const { commentStore, uiStore } = useRootStoreContext();
  const [text, setText] = React.useState("");
  const { toast } = useToast();

  const postComment = async () => {
    try {
      await commentStore.postComment(text);
      setText("");
    } catch (err) {
      toast({ title: "Failed to post comment", description: "WELP" });
    }
  };

  return (
    <div
      className={cn("bg-white p-4 w-full flex flex-col", !uiStore.commentsSidebarOpen && "hidden")}
    >
      <textarea
        className="resize-none w-full h-24 text-sm
        rounded-md border-slate-200 bg-slate-100
        focus:bg-white focus:ring-blue-400 focus:border-blue-400"
        placeholder="Add a comment..."
        onKeyDown={(e) => {
          if (e.metaKey && e.key == "Enter") {
            postComment();
          }
        }}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
          setText(e.target.value);
        }}
        value={text}
      />
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant="outline"
              className="place-self-end mt-2"
              onClick={async () => {
                postComment();
              }}
            >
              Comment
            </Button>
          </TooltipTrigger>
          <TooltipContent className="p-2">
            <HotKeyToolTip keys={["⌘", "Enter"]} title={"To post a comment"} />
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  );
});

const CommentsSidebar = observer(({ className }) => {
  const { uiStore } = useRootStoreContext();

  return (
    <div
      className={cn(
        "grow relative flex flex-col bg-white z-10",
        "transition-width duration-500",
        uiStore.commentsSidebarOpen ? "w-1/4" : "w-0",
        className
      )}
    >
      <Comments />
      <AddComment />
    </div>
  );
});

export const ProjectView = observer(() => {
  const rootStore = useRootStoreContext();
  const { id } = useParams();

  React.useEffect(() => {
    if (id) {
      rootStore.projectStore.setCurrentProject(id);
      rootStore.canvasStore.initialize();
    }
    return () => {
      runInAction(() => {
        rootStore.projectStore.currentProject = null;
        rootStore.canvasStore.dispose();
      });
    };
  }, [id]);

  if (rootStore.projectStore.currentProject === null) {
    return null;
  } else if (rootStore.uiStore.httpError) {
    return (
      <Foobar
        title={rootStore.uiStore.httpError.message}
        description={rootStore.uiStore.httpError.description}
        emoji="🕵️‍♂️"
      />
    );
  } else {
    return (
      <div className="flex flex-col w-screen h-screen">
        <MenuBar />
        <div className="flex flex-row w-full h-[calc(100%-64px)]">
          <AssetContent />
          <CommentsSidebar className="hidden sm:flex" />
        </div>
      </div>
    );
  }
});
