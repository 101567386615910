import { computed, makeObservable, observable, runInAction } from "mobx";
import { RootStore } from "./RootStore";
import { User } from "../models/User";

export class AuthStore {
  userId: string | null = null;
  rootStore: RootStore;
  tempName: string = "";

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this, { userId: observable, user: computed, tempName: observable });
    this.getMe();
  }

  get user(){
    if (this.userId) {
      return this.rootStore.userStore.get(this.userId!);
    } else {
      return null;
    }
  }

  setTempName(name: string){
    this.tempName = name;
  }

  async getMe() {
    const response = await fetch("/me", {
      headers: { "Content-Type": "application/json" },
    });
    if (response.ok) {
      const json = await response.json();
      runInAction(() => {
        this.rootStore.userStore.add(json);
        this.userId = json.id;
        this.tempName = json.name;
      });
    } else {
      return {};
    }
  }

  get tokenResponse() {
    const token = document.getElementsByName("csrf-token")[0] as HTMLMetaElement;
    return { "X-CSRF-Token": token.content };
  }

  signOut = async () => {
    const response = await fetch("api/sign_out", {
      method: "POST",
      headers: { "Content-Type": "application/json", ...this.tokenResponse },
    });
    if (response.ok) {
      runInAction(() => {
        window.location.href = "/";
      });
    }
  };

  updateSettings = async () => {
    if (!this.user) return;
    const token = document.getElementsByName("csrf-token")[0] as HTMLMetaElement;
    const raw = await fetch("api/users/update", {
      method: "POST",
      headers: { "Content-Type": "application/json", "X-CSRF-Token": token.content },
      body: JSON.stringify({ name: this.tempName }),
    });
    const user_json = await raw.json()
    this.rootStore.userStore.add(user_json);
    this.tempName = this.user.name || "";
  };
}
