import React from "react";

interface HotKeyToolTipProps {
  keys: string[];
  title?: string;
}

export const HotKeyToolTip = (props: HotKeyToolTipProps) => {
  return (
    <span className="py-2">
      {props.keys.map((key) => ( 
        <span key={key} className="rounded-[2px] aspect-square 
        bg-slate-100 drop-shadow-sm border-[1px] border-slate-100 p-[2px]
        font-medium text-[9px] text-slate-800 mr-2">{key}</span>
      ))}
      {props.title}
    </span>
  );
};
