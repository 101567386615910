import { observable, set, action, makeObservable } from "mobx";
import { RootStore } from "../stores/RootStore";

export abstract class BaseModel {
  id: string;
  // TODO: Debatable if this should be exposed to the client
  createdAt: Date;
  updatedAt: Date;
  store: RootStore;

  constructor(store: RootStore, fields: Record<string, any>) {
    this.id = String(fields.id);
    this.createdAt = fields.created_at && new Date(fields.created_at);
    this.updatedAt = fields.updated_at && new Date(fields.updated_at);
    this.store = store;
    makeObservable(this, {
      id: observable,
      updateFromJson: action,
      tailCalls: action.bound,
      createdAt: observable,
      updatedAt: observable,
    });
  }

  updateFromJson = (data: any) => {
    set(this, { ...data });
  };

  tailCalls() {
    // By default, there are no tail calls
  }
}
