import { action, computed, makeObservable, observable } from "mobx";
import { RootStore } from "../stores/RootStore";
import { BaseModel } from "~/models/BaseModel";
import { toUpper } from "lodash";

interface UserExtras { 
  avatar_color?: string  
}

export class User extends BaseModel {
  email!: string;
  name?: string;
  extra?: UserExtras;
 
  constructor(store: RootStore, data: any) {
    super(store, data);
    makeObservable(this, {
      email: observable,
      name: observable,
      extra: observable,
      initials: computed,
      displayName: computed,
      avatarColor: computed,
      setName: action,
    });
    this.updateFromJson(data);
  }

  setName = (name: string) => {
    this.name = name;
  };

  get initials() {
    if (this.name) {
      return toUpper(
        this.name
          .split(" ")
          .map((n) => n[0])
          .join("")
      );
    } else { 
      return this.email?.split("@")[0][0].toUpperCase()
    }
  }

  get avatarColor(): string { 
    return this.extra?.avatar_color ?? "#0f172a"
  }

  get displayName() {
    if (this.name) { 
      return this.name
    } else { 
      return this.email?.split("@")[0]
    }
  }
}
