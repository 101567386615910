import React from "react";
import { Logo } from "./Logo";

export const Footer = () => {
  return (
    <div className="border-t border-slate-200 p-8 w-full flex flex-col gap-6">
      <a href="https://www.studiolanes.com" target="_blank" className="flex gap-2 font-medium">
        <Logo fill="black" />
        studiolanes
      </a>

      <div className="flex gap-6 sm:gap-16 items-center">
        <a href="https://twitter.com/studiolanes" target="_blank" className="text-2xl">
          &#x1D54F;
        </a>
        <a href="https://www.studiolanes.com" target="_blank" className="text-slate-400 text-sm">
          Home
        </a>
        <a href="mailto:team@studiolanes.com" target="_blank" className="text-slate-400 text-sm">
          Contact
        </a>
        <div className="flex-1" />
        <div className="hidden sm:inline text-slate-400 text-sm">Made in San Francisco, CA, USA</div>
      </div>
    </div>
  );
};
