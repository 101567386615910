import React from "react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "~/@/components/ui/tabs";
import { CalendarIcon } from "@radix-ui/react-icons";
import { MessageSquare, MoreHorizontal, Plus } from "lucide-react";
import { Separator } from "~/@/components/ui/separator";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/@/components/ui/select";
import { UserProfile } from "./UserProfile";
import { useRootStoreContext } from "./context";
import { observer } from "mobx-react";
import { Dialog, DialogTrigger } from "~/@/components/ui/dialog";
import { useNavigate } from "react-router-dom";
import { Project } from "~/models/Project";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "~/@/components/ui/dropdown-menu";
import { format } from "date-fns";
import { action } from "mobx";
import { CreatePostDialogContent } from "./CreatePostDialogContent";
import { ProjectEmptyState } from "./ProjectEmptyState";
import { Footer } from "./Footer";
import { SettingsView } from "./SettingsView"

const ProjectSettingsDots = observer(({ project }: { project: Project }) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="p-2 font-medium flex flex-row items-center rounded-md rounded-md">
        <MoreHorizontal size={18} />
      </DropdownMenuTrigger>
      <DropdownMenuContent className="bg-white">
        <DropdownMenuItem
          onClick={action((e) => {
            e.stopPropagation();
            project.archiveAction();
          })}
        >
          {project.archiveActionLiteral}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
});

const ProjectCardMeta = observer(({ project }: { project: Project }) => {
  return (
    <div className="p-4 flex flex-col gap-2">
      <div className="flex justify-between">
        <div className="font-medium">{project.title || "Untitled"}</div>
        <ProjectSettingsDots project={project} />
      </div>

      <div className="flex gap-2 text-sm text-slate-400 justify-between">
        <div className="flex">
          <div className="flex gap-1">
            <MessageSquare size={18} />
            <div>{project.latestSketch?.numComments || 0}</div>
          </div>
          {/* <div className="flex gap-1">
            <BarChart size={18} />
            <div>24</div>
          </div> */}
        </div>
        <div>{`${format(project.createdAt, "PP")}`}</div>
      </div>
    </div>
  );
});

const ProjectCardContent = observer(({ project }: { project: Project }) => {
  const navigate = useNavigate();
  return (
    <div
      className="flex flex-col rounded-lg border cursor-pointer"
      onClick={() => navigate(`projects/${project.id}`)}
    >
      <img
        className="object-cover h-40 rounded-t-md bg-blue-100"
        src={project.latestSketch?.firstAsset?.url}
      ></img>
      <ProjectCardMeta project={project} />
    </div>
  );
});

const ProjectsList = observer(({ value }: { value: string }) => {
  const { projectStore, uiStore } = useRootStoreContext();
  const projects = projectStore.fetchProjects(
    value,
    uiStore.sortProperties.map((x) => x.key),
    uiStore.sortProperties.map((x) => x.direction)
  );
  return projects.length == 0 ? (
    <ProjectEmptyState />
  ) : (
    <div className="w-full max-w-[1000px] h-full grow pt-4">
      <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-4 grow">
        {projects.map((project) => (
          <ProjectCardContent key={project.id} project={project} />
        ))}
      </div>
    </div>
  );
});

const ProjectCollectionView = observer(({ value }: { value: string }) => {
  const { uiStore } = useRootStoreContext();
  return (
    <TabsContent value={value} className={uiStore.tabsValue === value ? "flex flex-col grow" : ""}>
      <Select
        onValueChange={(value) => {
          if (value === "oldest") {
            uiStore.setSortProperties([{ key: "createdAt", direction: "asc" }]);
          } else if (value === "newest") {
            uiStore.setSortProperties([{ key: "createdAt", direction: "desc" }]);
          }
        }}
        defaultValue="newest"
      >
        <SelectTrigger className="w-[180px] mx-6">
          <CalendarIcon />
          <SelectValue placeholder="Sort by newest" />
        </SelectTrigger>
        <SelectContent className="bg-white">
          <SelectItem value="newest">Sort by newest</SelectItem>
          <SelectItem value="oldest">Sort by oldest</SelectItem>
        </SelectContent>
      </Select>
      <Separator className="mt-4 bg-slate-200" />
      <div className="flex flex-1 flex-col justify-center items-center pt-4 px-6">
        <ProjectsList value={value} />
      </div>
    </TabsContent>
  );
});


const MainContent = observer(() => {
  const { uiStore } = useRootStoreContext();
  return (
    <Tabs
      value={uiStore.tabsValue}
      onValueChange={uiStore.setTabsValue}
      style={{
        flexGrow: "1",
        display: "flex",
        flexDirection: "column",
        gap: "0em",
        marginTop: "88px",
      }}
    >
      <TabsList className="ml-6 px-6 place-self-start">
        <TabsTrigger value="active">Active</TabsTrigger>
        <TabsTrigger value="archived">Archived</TabsTrigger>
        <TabsTrigger value="settings">Settings</TabsTrigger>
      </TabsList>
      <Separator className="mt-4 mb-2 bg-slate-200" />
      <ProjectCollectionView value={"active"} />
      <ProjectCollectionView value={"archived"} />
      <SettingsView value={"settings"} />
    </Tabs>
  );
});

const Header = () => {
  return (
    <div className="flex flex-row justify-between w-full p-6 fixed bg-white z-10">
      <UserProfile />
      <Dialog>
        <DialogTrigger className="bg-black text-white rounded-md hover:bg-primary/90">
          <div className="flex items-center px-3 gap-2 text-[15px]">
            <Plus size={19} /> <span className="hidden sm:inline">Create post</span>
          </div>
        </DialogTrigger>
        <CreatePostDialogContent />
      </Dialog>
    </div>
  );
};

export const Home = () => {
  return (
    <div className="relative flex flex-col w-screen min-h-screen">
      <Header />
      <div className="flex flex-col grow pb-20">
        <MainContent />
      </div>
      <Footer />
    </div>
  );
};
