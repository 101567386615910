import React from "react";
import { RootStore } from "~/stores/RootStore";
import { RootStoreContext, useRootStoreContext } from "./context";

import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";
import { Home } from "./Home";
import { ProjectView } from "./ProjectView";
import { configure } from "mobx";
import { Toaster } from "~/@/components/ui/toaster";

if (process.env.NODE_ENV !== "production") {
  configure({
    enforceActions: "always",
    computedRequiresReaction: true,
    reactionRequiresObservable: true,
    observableRequiresReaction: true,
    disableErrorBoundaries: true,
  });
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/projects/:id",
    element: <ProjectView />,
  },
  {
    path: "*",
    element: <Navigate to="/" replace />,
  },
]);

export const App = () => {
  const [rootStore, _] = React.useState(() => new RootStore());

  return (
    <RootStoreContext.Provider value={rootStore}>
      <RouterProvider router={router} />
      <Toaster />
    </RootStoreContext.Provider>
  );
};
