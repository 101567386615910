import { BaseModel } from "~/models/BaseModel";
import { RootStore } from "~/stores/RootStore";
import { computed, makeObservable, observable } from "mobx";
import { Sketch } from "~/models/Sketch";

let ASSET_URL: string;

if (process.env.NODE_ENV === "production") {
  ASSET_URL = "studiolanes-prod-assets";
} else {
  ASSET_URL = "studiolanes-dev-assets";
}

export class Asset extends BaseModel {
  name?: string;
  size?: bigint;
  extension?: string;
  sketch_id!: string;
  width!: number;
  height!: number;

  constructor(store: RootStore, data: any) {
    super(store, data);
    makeObservable(this, {
      name: observable,
      size: observable,
      extension: observable,
      width: observable,
      height: observable,
      url: computed,
      sketch: computed,
      sketch_id: observable,
    });
    this.updateFromJson(data);
  }

  get sketch(): Sketch | undefined {
    return this.store.sketchStore.get(this.sketch_id);
  }

  get url(): string {
    const a = `https://${ASSET_URL}.s3.amazonaws.com/${this.name}`;
    return a;
  }
}
