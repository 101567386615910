import React from "react";
import { Plus } from "lucide-react";
import { Button } from "~/@/components/ui/button";
import { CreatePostDialogContent } from "./CreatePostDialogContent";
import { Dialog } from "~/@/components/ui/dialog";
import { DialogTrigger } from "@radix-ui/react-dialog";

export const ProjectEmptyState = () => {
    return (
        <div className="place-self-center flex flex-col grow gap-8 justify-center h-full items-center">
            <div className="font-semibold">
                No projects.
            </div>
            <div className="text-slate-500 text-center">
                This space is waiting for you.
                <br />
                Share your work now to start a conversation.
            </div>

            <div className="flex flex-col gap-4 items-center">
                <Dialog>
                    <DialogTrigger asChild>
                        <Button variant="outline">
                            <Plus size={18} className="mr-2" />
                            Create post
                        </Button>
                    </DialogTrigger>
                    <CreatePostDialogContent />
                </Dialog>

                {/* <button className="text-sm text-slate-500 pointer flex gap-2 items-center">
                    Learn more
                    <ExternalLink size={14} />
                </button> */}
            </div>
        </div>
    );
};