import { makeObservable, observable, runInAction, toJS } from "mobx";
import { RootStore } from "./RootStore";
import { BaseStore } from "./BaseStore";
import { Comment } from "../models/Comment";
import { Sketch } from "../models/Sketch";
import { Coordinate2D } from "./CanvasStore";

export class SketchStore extends BaseStore<Sketch> {
  currentSketch: Sketch | null = null;

  constructor(rootStore: RootStore) {
    super(rootStore, Sketch);
    makeObservable(this, { currentSketch: observable });
  }

  async postComment(
    text: string,
    sketch_id: string,
    point?: Coordinate2D,
    asset_id?: string
  ): Promise<Comment> {
    const newComment = await this.rootStore.commentStore.create({
      text,
      sketch_id,
      point,
      asset_id,
    });

    const sketch = this.get(sketch_id);
    if (sketch) {
      runInAction(() => {
        sketch.comments.replace([...sketch.comments, newComment]);
      });
    }
    return newComment;
  }
}
