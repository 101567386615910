import { User } from "lucide-react";
import { AuthStore } from "./AuthStore";
import { UserStore } from "./UserStore";
import { ProjectStore } from "./ProjectStore";
import { CommentStore } from "./CommentStore";
import { SketchStore } from "./SketchStore";
import { AssetStore } from "./AssetStore";
import { UiStore } from "./UiStore";
import { CanvasStore } from "./CanvasStore";

export class RootStore {
  authStore: AuthStore;
  projectStore: ProjectStore;
  assetStore: AssetStore;
  commentStore: CommentStore;
  sketchStore: SketchStore;
  userStore: UserStore;
  uiStore: UiStore;
  canvasStore: CanvasStore;

  constructor() {
    this.authStore = new AuthStore(this);
    this.userStore = new UserStore(this);
    this.projectStore = new ProjectStore(this);
    this.assetStore = new AssetStore(this);
    this.commentStore = new CommentStore(this);
    this.sketchStore = new SketchStore(this);
    this.uiStore = new UiStore(this);
    this.canvasStore = new CanvasStore(this);
  }
}
