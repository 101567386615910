import { IObservableArray, computed, makeObservable, observable, runInAction } from "mobx";
import { RootStore } from "../stores/RootStore";
import { BaseModel } from "./BaseModel";
import { Comment } from "./Comment";
import { Project } from "./Project";
import { Asset } from "./Asset";
import { orderBy } from "lodash";

export class Sketch extends BaseModel {
  version?: number;

  comments: IObservableArray<Comment> = observable.array([]);
  assets: IObservableArray<Asset> = observable.array([]);

  project_id!: string;

  constructor(store: RootStore, data: any) {
    super(store, data);
    makeObservable(this, {
      version: observable,
      comments: observable,
      assets: observable,
      project: computed,
      firstAsset: computed,
      numComments: computed,
      reverseDateSortedComments: computed,
      dateAscComments: computed,
    });
    this.updateFromJson(data);

    this.tailCalls();
  }

  tailCalls(): void {
    runInAction(() => {
      this.assets.replace(
        this.assets.map((s) => {
          return this.store.assetStore.add(s);
        })
      );

      this.comments.replace(
        this.comments.map((s) => {
          return this.store.commentStore.add(s);
        })
      );
    });
  }

  get assetComments() {
    return this.comments.filter((x) => x.asset_id && x.point);
  }

  get reverseDateSortedComments(): Comment[] {
    return orderBy(this.comments, ["createdAt"], ["desc"]);
  }

  get dateAscComments() {
    return orderBy(this.comments, ["createdAt"], ["asc"]);
  }

  get project(): Project | undefined {
    return this.store.projectStore.get(this.project_id);
  }

  get firstAsset(): Asset | undefined {
    return this.assets[0];
  }

  get numComments() {
    return this.comments.length;
  }
}
