import {
  IObservableArray,
  action,
  computed,
  makeObservable,
  observable,
  override,
  runInAction,
} from "mobx";
import { RootStore } from "../stores/RootStore";
import { BaseModel } from "./BaseModel";
import { Sketch } from "~/models/Sketch";
import { maxBy } from "lodash";
import { FileMeta } from "../stores/ProjectStore";

export class Project extends BaseModel {
  title?: string;
  status!: "active" | "archived";
  visibility!: number; // maybe enum later
  user_id!: string

  sketches: IObservableArray<Sketch> = observable.array([]);
  fileMeta?: FileMeta;

  constructor(store: RootStore, data: any) {
    super(store, data);
    makeObservable(this, {
      title: observable,
      user_id: observable,
      fileMeta: observable,
      sketches: observable,
      status: observable,
      visibility: observable,
      latestSketch: computed,
      isPrivate: computed,
      isPublic: computed,
      archiveActionLiteral: computed,
      tailCalls: override,
      setTitle: action,
      setVisibility: action,
    });
    this.updateFromJson(data);
    this.tailCalls();
  }

  tailCalls(): void {
    runInAction(() => {
      this.sketches.replace(
        this.sketches.map((s) => {
          return this.store.sketchStore.add(s);
        })
      );
    });
  }

  get isPrivate(): boolean {
    return this.visibility === 0;
  }

  get isPublic(): boolean {
    return !this.isPrivate;
  }

  get latestSketch(): Sketch | undefined {
    return maxBy(this.sketches, (o) => {
      return o.version;
    });
  }

  get archiveActionLiteral(): string {
    return this.status === "active" ? "Archive" : "Restore";
  }

  archiveAction = () => {
    if (this.status === "active") {
      this.store.projectStore.archive(this);
    } else {
      this.store.projectStore.unarchive(this);
    }
  };

  setTitle = (title: string) => {
    if (title.length > 0) {
      this.title = title;
    }
  };

  setVisibility = (visibility: number) => {
    this.visibility = visibility;
  };
}
