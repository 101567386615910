import {
  IObservable,
  IObservableArray,
  IObservableFactory,
  IReactionDisposer,
  action,
  autorun,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import { RootStore } from "./RootStore";
import Konva from "konva";
import { debounce } from "lodash";
import { Sketch } from "~/models/Sketch";

interface CanvasSize {
  width: number;
  height: number;
}

export interface Coordinate2D {
  x: number;
  y: number;
}

const SCALE_BY = 1.03;
type CursorMode = "pointer" | "comment";

function getDistance(p1: Coordinate2D, p2: Coordinate2D) {
  return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
}

function getCenter(p1: Coordinate2D, p2: Coordinate2D) {
  return {
    x: (p1.x + p2.x) / 2,
    y: (p1.y + p2.y) / 2,
  };
}

export class CanvasStore {
  store: RootStore;
  sketch: Sketch | null = null;
  dimensions: CanvasSize = { width: 0, height: 0 };
  imageDimensions: CanvasSize = { width: 0, height: 0 };
  imageOffset: Coordinate2D = { x: 0, y: 0 };
  stage: Konva.Stage | null = null;
  origin: Coordinate2D = { x: 0, y: 0 };
  scale: number = 1;
  savedItems: IObservableArray<Coordinate2D> = observable.array([]);
  reactionDisposer!: IReactionDisposer;
  initialized: boolean = false;
  cursorMode: CursorMode = "pointer";
  activeCommentID?: string = undefined;
  canvasClickCoord?: Coordinate2D = undefined;
  imageLoaded: boolean = false;
  lastCenter: Coordinate2D | null = null;
  lastDist = 0;

  constructor(store: RootStore) {
    this.store = store;
    makeObservable(this, {
      savedItems: observable,
      dimensions: observable,
      origin: observable,
      stage: observable,
      imageOffset: observable,
      scale: observable,
      initialized: observable,
      canvasClickCoord: observable,
      cursorMode: observable,
      activeCommentID: observable,
      imageLoaded: observable,
      lastCenter: observable,
      lastDist: observable,
      setCanvasClickCoord: action,
      setActiveCommentID: action,
      setCursorMode: action,
      setDimensions: action,
      setOrigin: action,
      setImageOffset: action,
      setScale: action,
      render: action,
      dispose: action,
    });
  }

  initialize() {
    this.reactionDisposer?.();
    this.reactionDisposer = autorun(() => {
      if (this.dimensions.height || this.dimensions.width) {
        this.render();
      }
    });
    window.addEventListener("keydown", this.listenForEscape);
  }

  listenForEscape = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      runInAction(() => {
        this.activeCommentID = undefined;
        this.canvasClickCoord = undefined;
      });
    }
  };

  dispose = () => {
    this.sketch = null;
    this.stage = null;
    this.initialized = false;
    this.imageLoaded = false;
    this.reactionDisposer();
    window.removeEventListener("keydown", this.listenForEscape);
  };

  setOrigin(coordinate: Coordinate2D) {
    this.origin = coordinate;
  }

  setImageOffset(value: Coordinate2D) {
    this.imageOffset = value;
  }

  setDimensions(dimensions: CanvasSize) {
    this.dimensions = dimensions;
  }

  setActiveCommentID(id: string) {
    this.canvasClickCoord = undefined;
    this.activeCommentID = id;
  }

  setScale(scale: number) {
    this.scale = scale;
  }

  setCanvasClickCoord(coord: Coordinate2D) {
    this.activeCommentID = undefined;
    this.canvasClickCoord = coord;
  }

  setCursorMode(value: CursorMode) {
    this.cursorMode = value;
    if (this.cursorMode === "pointer") {
      this.activeCommentID = undefined;
      this.canvasClickCoord = undefined;
    }
  }

  handleZoom(thisStage: Konva.Stage) {
    thisStage.on("wheel", (e) => {
      e.evt.preventDefault();

      var oldScale = thisStage.scaleX();
      var pointer = thisStage.getPointerPosition()!;

      var mousePointTo = {
        x: (pointer.x - thisStage.x()) / oldScale,
        y: (pointer.y - thisStage.y()) / oldScale,
      };

      // how to scale? Zoom in? Or zoom out?
      let direction = e.evt.deltaY > 0 ? -1 : 1;

      var newScale = direction > 0 ? oldScale * SCALE_BY : oldScale / SCALE_BY;

      thisStage.scale({ x: newScale, y: newScale });
      this.setScale(newScale);

      var newPos = {
        x: pointer.x - mousePointTo.x * newScale,
        y: pointer.y - mousePointTo.y * newScale,
      };
      thisStage.position(newPos);
      this.setOrigin(newPos);
    });
    thisStage.on("touchmove", (e) => {
      e.evt.preventDefault();
      var touch1 = e.evt.touches[0];
      var touch2 = e.evt.touches[1];
      if (touch1 && touch2) {
        // if the stage was under Konva's drag&drop
        // we need to stop it, and implement our own pan logic with two pointers
        thisStage.draggable(false);
        if (thisStage.isDragging()) {
          thisStage.stopDrag();
        }
        var p1 = { x: touch1.clientX, y: touch1.clientY };
        var p2 = { x: touch2.clientX, y: touch2.clientY };

        if (!this.lastCenter) {
          this.lastCenter = getCenter(p1, p2);
          return;
        }
        var newCenter = getCenter(p1, p2);

        var dist = getDistance(p1, p2);

        if (!this.lastDist) {
          this.lastDist = dist;
        }

        // local coordinates of center point
        var pointTo = {
          x: (newCenter.x - thisStage.x()) / thisStage.scaleX(),
          y: (newCenter.y - thisStage.y()) / thisStage.scaleX(),
        };

        var scale = thisStage.scaleX() * (dist / this.lastDist);

        thisStage.scaleX(scale);
        thisStage.scaleY(scale);
        this.setScale(scale);

        // calculate new position of the stage
        var dx = newCenter.x - this.lastCenter.x;
        var dy = newCenter.y - this.lastCenter.y;

        var newPos = {
          x: newCenter.x - pointTo.x * scale + dx,
          y: newCenter.y - pointTo.y * scale + dy,
        };

        thisStage.position(newPos);
        this.setOrigin(newPos);

        this.lastDist = dist;
        this.lastCenter = newCenter;
      }
    });

    thisStage.on("touchend", () => {
      thisStage.draggable(true);
      this.lastDist = 0;
      this.lastCenter = null;
    });
  }

  handleComments(thisStage: Konva.Stage, thisGroup: Konva.Group) {
    thisStage.on("click", () => {
      if (this.cursorMode === "comment") {
        var pos = thisGroup.getRelativePointerPosition();
        const position = { x: pos.x - this.imageOffset.x, y: pos.y - this.imageOffset.y };
        this.setCanvasClickCoord(position);
      }
    });
  }

  handlePanning(thisStage: Konva.Stage) {
    thisStage.on("dragmove", () => {
      const pos = thisStage.position();
      if (pos) {
        this.setOrigin({ x: pos.x, y: pos.y });
      }
    });
  }

  render = () => {
    const latestSketch = this.store.projectStore.currentProject?.latestSketch;
    const firstAsset = latestSketch?.firstAsset;
    if (this.sketch === null || !firstAsset?.url) {
      return;
    }

    const actualWidth = firstAsset.width;
    const actualHeight = firstAsset.height;

    if (!this.initialized) {
      if (actualWidth > this.dimensions.width && actualWidth >= actualHeight) {
        const newScale = (this.dimensions.width * 0.8) / actualWidth;
        this.setScale(newScale);
      } else if (actualHeight > this.dimensions.height && actualHeight > actualWidth) {
        const newScale = (this.dimensions.height * 0.8) / actualHeight;
        this.setScale(newScale);
      } else if (
        actualWidth < this.dimensions.width / 2 ||
        actualHeight < this.dimensions.height / 2
      ) {
        const yScale = (this.dimensions.height * 0.8) / actualHeight;
        const xScale = (this.dimensions.width * 0.8) / actualWidth;
        this.setScale(Math.min(xScale, yScale));
      }
      this.initialized = true;
    }

    if (!this.stage) {
      this.stage = new Konva.Stage({
        container: "container",
        width: this.dimensions.width,
        height: this.dimensions.height,
        draggable: true,
        scale: { x: this.scale, y: this.scale },
      });

      var layer = new Konva.Layer();
      this.stage.add(layer);

      var group = new Konva.Group({ x: 0, y: 0, rotation: 0 });
      layer.add(group);

      const xOffset = this.dimensions.width / this.scale / 2 - actualWidth / 2;
      const yOffset = this.dimensions.height / this.scale / 2 - actualHeight / 2;

      this.setImageOffset({ x: xOffset, y: yOffset });

      var imageObj = new Image();

      imageObj.onload = () => {
        var yoda = new Konva.Image({
          x: this.imageOffset.x,
          y: this.imageOffset.y,
          image: imageObj,
          width: actualWidth,
          height: actualHeight,
        });
        group.add(yoda);
        runInAction(() => {
          const pos = this.stage!.position();
          if (pos) {
            console.log(pos);
            this.setOrigin({ x: pos.x, y: pos.y });
          }
          this.imageLoaded = true;
        });
      };
      imageObj.src = firstAsset.url;
      this.handleZoom(this.stage);
      this.handleComments(this.stage, group);
      this.handlePanning(this.stage);
    } else {
      this.stage.width(this.dimensions.width);
      this.stage.height(this.dimensions.height);
    }
  };
}
