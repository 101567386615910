import React from "react";

export interface LogoProps {
  fill?: string;
}

export const Logo = (props: LogoProps) => {
  return (
    <svg
      width="19"
      height="24"
      viewBox="0 0 294 374"
      fill="current"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M130.778 34.2728C130.778 29.2916 134.816 25.2536 139.797 25.2536H293.805V0H121.759C112.793 0 105.524 7.2684 105.524 16.2345V90.6159V156.997C105.524 160.982 102.294 164.213 98.3087 164.213H16.2345C7.26844 164.213 0 171.481 0 180.447V374H25.2536V198.486C25.2536 193.504 29.2916 189.466 34.2728 189.466H114.543C123.509 189.466 130.778 182.198 130.778 173.232V34.2728Z"
        fill={props.fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M170.462 70.3494C170.462 65.3682 174.5 61.3302 179.481 61.3302H293.805V36.0766H161.443C152.477 36.0766 145.208 43.345 145.208 52.3111V194.878C145.208 198.863 141.978 202.093 137.993 202.093H55.9187C46.9527 202.093 39.6843 209.362 39.6843 218.328V374H64.9379V236.366C64.9379 231.385 68.9759 227.347 73.957 227.347H154.227C163.194 227.347 170.462 220.078 170.462 211.112V70.3494Z"
        fill={props.fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M208.342 110.034C208.342 105.053 212.38 101.014 217.362 101.014H293.805V75.7609H199.323C190.357 75.7609 183.089 83.0293 183.089 91.9953V230.954C183.089 234.939 179.858 238.17 175.873 238.17H93.7992C84.8331 238.17 77.5647 245.438 77.5647 254.404V374H102.818V272.443C102.818 267.461 106.856 263.423 111.837 263.423H192.108C201.074 263.423 208.342 256.155 208.342 247.189V110.034Z"
        fill={props.fill}
      />
    </svg>
  );
};
