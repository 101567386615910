import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "~/@/components/ui/button";
import { Footer } from "./Footer";
import { HotKeyToolTip } from "./KeyTooltip";

interface FoobarProps {
  emoji?: string;
  title?: string;
  description?: string;
}

export const Foobar = (props: FoobarProps) => {
  const navigate = useNavigate();

  useEffect(() => {
    const listener = (event: any) => {
      if (event.code === "Enter") {
        event.preventDefault();
        navigate("/")
      }
    };

    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  return (
    <div className="relative w-screen h-screen flex flex-col items-center justify-center gap-6">
      <div className="flex flex-col items-center gap-2">
        <div className="text-5xl mb-4">{props.emoji ?? "😖"}</div>
        <div className="font-semibold">{props.title ?? "err."}</div>
        <div className="text-slate-500">{props.description ?? "unknown reason"}</div>
      </div>
        <Button onClick={() => navigate("/")}>
          <div className="flex gap-4 items-center">
            <span className="">Go home</span>
            <HotKeyToolTip keys={["Enter"]} />
          </div>
        </Button>
    </div>
  );
};

