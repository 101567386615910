import React from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "~/@/components/ui/dropdown-menu";
import { CaretSortIcon } from "@radix-ui/react-icons";
import { observer } from "mobx-react";
import { useRootStoreContext } from "./context";
import { UserAvatar } from "./UserAvatar";

export const UserProfile = observer(() => {
  const { authStore, uiStore } = useRootStoreContext();

  return (
    <div className="flex flex-row gap-2">
      <UserAvatar user={authStore.user ?? undefined} size="40px" />
      <DropdownMenu>
        <DropdownMenuTrigger>
          <div className="flex flex-row items-center gap-2">
            <div className="font-medium">{authStore.user?.name || authStore.user?.email}</div>
            <CaretSortIcon />
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="bg-white">
          <DropdownMenuItem onClick={() => uiStore.setTabsValue("settings")}>
            Settings
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem onClick={authStore.signOut}>Sign out</DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
});
