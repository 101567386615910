export class HTTPError extends Error { 
    code: number;
    description?: string

    constructor(code: number, message?: string, description?: string) { 
        super(message)
        this.code = code
        this.description = description
    }
}

export class NotFoundError extends HTTPError { 
    constructor(message: string = "Not found") { 
        super(404, message, "That doesn't seem to exist?")
    }
}

export class AuthError extends HTTPError { 
    constructor(message: string = "Invalid permissions") { 
        super(403, message, "You don't have the right permissions to view this.")
    }
}

/** ----- */

export class HTTPErrorFactory { 
    public static error(status: number): HTTPError { 
        switch (status) { 
            case 403:
                return new AuthError("For their eyes only")
            case 404:
                return new NotFoundError()
            default:
                return new HTTPError(status, "Error with unknown status code")
        }
    }
}