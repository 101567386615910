import { BaseStore } from "~/stores/BaseStore";
import { RootStore } from "~/stores/RootStore";
import { computed } from "mobx";
import { Comment } from "../models/Comment";
import { Coordinate2D } from "./CanvasStore";

export class CommentStore extends BaseStore<Comment> {
  constructor(rootStore: RootStore) {
    super(rootStore, Comment);
  }

  async postComment(text: string, point?: Coordinate2D): Promise<Comment | undefined> {
    if (text === "") {
      return undefined;
    }

    // TODO: Move this to caller?
    // There are a lot of assumptions here
    const sketch = this.rootStore.projectStore.currentProject?.latestSketch;
    return await this.rootStore.sketchStore
      .postComment(text, sketch!.id, point, sketch!.firstAsset!.id)
      .catch((err) => {
        throw err;
      });
  }
}
